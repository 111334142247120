import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SidewaysLogo from './sidewaysLogo'

const Header = ({ siteTitle, logo }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
      <nav>
        <div style={{ width: `18em` }}><Link to="/"><SidewaysLogo /></Link></div>
        <ul>
          <li>
            <Link to="/weddings">Weddings</Link>
          </li>
          <li>
            <Link to="/engagements">Engagements</Link>
          </li>
          <li>
            <Link to="/commercial">Commercial</Link>
          </li>
          <li>
            <Link to="/music-video">Music Video</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
